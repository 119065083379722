<template>
  <div>
    <div class="bg mt-10">
        <v-row class="justify-center">
          <v-col cols="12" md="6" sm="8">
            <v-card elevation="1" class="mx-auto rounded-lg">
              <v-row class="justify-center">
                <v-col cols="12" md="10" sm="10" class="pb-16 px-8">
                  <div class="text-center mt-2">
                    <v-icon x-large color="green">mdi-email-outline</v-icon>
                  </div>
                  <div class="d-flex flex-column mt-4 font-weight-bold subtitle-1 text-center green--text">
                    RESET SUCCESS
                  </div>
                  <v-divider></v-divider>
                  <div class="mt-4 mx-4 subtitle-2 font-weight-light text-center">
                    Your account has been successfully created, please login.
                  </div>
                  <div class="text-center grey--text mt-2 caption">
                    Wait : {{ counter }} seconds
                  </div>

                  <div class="mt-8"></div>
                  <div class="text-center">
                    <span>
                    <v-btn @click.prevent="toLogin" small rounded dark color="primary" class="ma-1" style="text-transform: none !important; text-decoration: none">
                      Login
                    </v-btn>
                  </span>
                  </div>
                  <v-divider></v-divider>
                  <div class="text-center d-flex mt-2">
                    <v-spacer></v-spacer>
                    <v-img
                        :src="require('@/assets/images/logo_silayak.png')"
                        max-height="100"
                        max-width="100"></v-img>
                    <v-spacer></v-spacer>
                  </div>
                  <div class="text-center grey--text mt-1 subtitle-2">
                    BKPSDM KOTA PALANGKARAYA 2024
                  </div>

                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "ResetSuccessView",
  data() {
    return {
      user: {
        otp: "",
      },
      counter: 20,
      errorMessage: "",
    };
  },
  computed: {
  },
  created() {

  },
  methods: {
    toLogin(){
      clearInterval(this.interval);
      this.$router.push({ path: "/login" });

    },
    startCountdown() {
      this.interval = setInterval(() => {
        if (this.counter > 0) {
          this.counter--;
        } else {
          clearInterval(this.interval);
          this.$router.push({ path: "/login" });
        }
      }, 1000);
    },
  },
  mounted() {
    this.startCountdown();
  },
};

</script>

<style scoped>
</style>